<template>
  <div class="p-1">
    <div class="flex justify-between items-center mb-2 flex-wrap">
      <h3 class="text-2xl">Especialidades</h3>
      <a-button :to="{ name: 'create_specialization' }" v-if="!user.isStudent && !user.isLowAdmin" class="w-full md:w-auto">
        Crear especialidad
      </a-button>
    </div>

    <a-table
      :columns="columns"
      :source="pagination.data"
      :loading="loading"
      toolbar-column-filter
      :pagination="pagination"
      @paginate="search({ page: $event })"
      @toolbar(refresh)="search({ page: 1 })">

      <template #toolbar(search)>
        <a-input
            is-search
            placeholder="Buscar..."
            @search="search({ search: $event, page: 1 })" />
      </template>

      <template #td(color)="{ item }">
        <span :style="{color: item.text_color}">{{ item.color }}</span>
      </template>
      <template #td(enabled)="{ item }">
        {{ item.enabled | boolean('Activa', 'Inactiva') }}
      </template>
      <template #td(actions)="{ item }">
        <a-dropdown v-if="!item.type.startsWith('basic')">
          <template #menu>
            <a-dropdown-item :to="{ name: 'edit_specialization', params: { id: item.id } }">
              Actualizar
            </a-dropdown-item>
            <a-dropdown-item @click="deleteSpecialization(item.id)">
              Eliminar
            </a-dropdown-item>
          </template>
        </a-dropdown>
        <template v-else>
          <span class="text-gray-400">
            -- Sin opciones --
          </span>
        </template>
      </template>
    </a-table>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  data: () => ({
    query: {
      limit: 10,
      with: 'pensums',
      search: '',
      sort_by: 'code,asc'
    },
    timeout: null
  }),
  computed: {
    ...mapState({
      user: state => state.session.user,
      pagination: state => state.specializations.pagination,
      loading: state => state.specializations.loading
    }),
    columns() {
      return [
        { title: 'Código', key: 'code', display: true },
        { title: 'Descripción', key: 'title', display: true },
        { title: 'Créditos', key: 'pensums', mutate: ({ key }) => key?.[key.length - 1]?.credits ?? '-', display: true },
        { title: 'Color', key: 'color', slot: 'color', alignment: 'center', style: (item) => ({ background: item.color }), display: true },
        { title: 'Estado', key: 'enabled', slot: 'enabled', alignment: 'right', display: true },
        { title: 'Acciones', slot: 'actions', alignment: 'right', columnFilterable: false, display: this.user.isAdmin },
      ].filter(column => column.display)
    }
  },
  methods: {
    ...mapActions({
      fetchSpecializations: 'specializations/fetchSpecializations',
      deleteSpecialization: 'specializations/delete'
    }),
    search(query) {
      this.fetchSpecializations({ ...this.query, ...query })
    }
  },
  mounted() {
    this.search({ ...this.query, page: this.$route.query.page || 1 })
  },
  created() {
    this.query = {
      ...this.query,
      ...this.$route.query
    }
  }
}
</script>
